<template>
    <div>
        <v-card>
            <v-toolbar dense>
                <v-toolbar-title>
                    <v-row style="margin-top: 10px">
                        <v-col cols="12" style="display: inline-flex;">
                            <v-chip
                                style="margin: 0px"
                                small
                                :color="'info'"
                            >
                                {{ "Ingreso QR" }} 
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-toolbar-title>
            </v-toolbar>
        </v-card>
        <v-card dense>
            <v-row justify="center" style="margin:auto">
                <v-col
                    align="center"
                    cols="8"
                    lg="11"
                    md="10"
                >   
                    <s-scanner-qr
                        :config="configScann"
                        return-object 
                        @onValue="onValue($event)"
                        :readonly="false"
                        :autofocus="false"
                        :clearInput="clearInput"
                        >
                    </s-scanner-qr>
                </v-col>
            </v-row>
        </v-card>
        <br>
        <v-row>
            <v-col sm="12" md="12">
                <v-card>
                <v-container>
                    <v-row>
                        <v-col sm="12" md="12" v-for="col in dataPallet" :key="col.PacID">
                            <v-card>
                                <v-container>
                                    <v-row>
                                        <v-col sm="12" md="7">
                                            <v-card-text style="height: 40px; background: #ecf5ff; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;">
                                                <b>
                                                    Listado Pallet
                                                </b>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-row>
                                                    <v-col cols="6" lg="6" md="6">
                                                        Pallet N° : {{ col.PalletGroupID }}
                                                    </v-col>
                                                    <v-col cols="6" lg="6" md="6">
                                                        ID Trazabilidad : {{ col.TraceabilityID }}
                                                    </v-col>
                                                    <v-col cols="6" lg="6" md="6">
                                                        Presentacion : {{ col.TypePackingName }}
                                                    </v-col>
                                                    <v-col cols="12" lg="12" md="12">
                                                    Cultivo : {{ col.TypeCultiveName }} / {{ col.TypeCropName }}
                                                    </v-col>
                                                    <v-col cols="6" lg="6" md="6">
                                                    N° Cajas : <v-chip color="success">{{ col.NumberBoxes }}</v-chip>
                                                    </v-col>
                                                    <v-col cols="6" lg="6" md="6">
                                                    Total (Kg) : <v-chip color="warning">{{ col.WeightNetBoxes.toFixed(2) }}</v-chip>
                                                    </v-col>
                                                </v-row>
                                            
                                            </v-card-actions>
                                        </v-col>   
                                        <v-divider vertical></v-divider>
                                        <v-col>
                                            <v-card-text style="height: 40px; background: #2196f3 ; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;">
                                                <b>
                                                    Detalle Merma
                                                </b>
                                            </v-card-text>
                                            <v-row>
                                                <v-col sm="12" md="12" v-for="i in col.palletDetails">
                                                    <v-card>
                                                        <v-container>
                                                            <v-row>
                                                                <v-col sm="12" md="12">
                                                                    <v-card-text style="height: 40px; background: #ecf5ff; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;">
                                                                        <b>
                                                                            Listado Pallet
                                                                        </b>
                                                                    </v-card-text>
                                                                    <v-card-actions>
                                                                        <v-row>
                                                                            <v-col cols="6" lg="6" md="6">
                                                                                N° Cajas : {{ i.NumberBoxesLosses }}
                                                                            </v-col>
                                                                            <v-col cols="6" lg="6" md="6">
                                                                                Peso Kg : {{ i.WeightNetBoxesLosses.toFixed(2) }}
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-card-actions>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card>
                                                </v-col>
                                                
                                            </v-row>
                                        </v-col>   
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container class="px-0">
                
                </v-container>
            </v-card>
            </v-col>
            
        </v-row>
    </div>
</template>
<script>
    import sScannerQr from '../../../../components/Utils/sScannerQr.vue';
    import _sFrzChamberPalletLosseService from "@/services/FrozenProduction/FrzChamberPalletLosses.js";

    export default{
        components: { sScannerQr},
        data:() =>({
            
            clearInput:false,
            configScann: {}, 
            itemHead:{},
            itemPallet: [],
            dataPallet: [],
            WeightNetBoxes:0.0,
            itemPalletLosses: 0,
            datailPallet: [],
            dataPacID:0,
            palletDetails:[]
            
            
        }),
        methods:{
            onValue(val)
            {
                
                if (val == "Sin datos" || isNaN(val)) {
					this.$fun.alert("El QR Leido no contiene datos", "warning");
				} else {
                    console.log('a guardar',val)
                    let item = {};
                    item.PalletGroupID = val;
                    _sFrzChamberPalletLosseService.list(item, this.$fun.getUserID())
                    .then( r => {
                        if(r.status == 200)
                        {
                            this.dataPallet = r.data;
                            console.log('Data pallet',this.dataPallet);
                        }
                    });
                }  
            },
            saveLosse(){
                // console.log('Guardar mermas');
                let val = {
                    PacID: this.itemPalletLosses,
                    NumberBoxesLosses: this.itemHead.CountBoxLosses,
                    WeightNetBoxesLosses: this.itemHead.CountLossesKg,
                    Observations: this.itemHead.Observaciones,
                    UsrCreateID: this.$fun.getUserID(),
                    UsrUpdateID: this.$fun.getUserID(),
                }

                if(this.itemPalletLosses == 0){
                    this.$fun.alert('Seleccione item','warning');
                    return
                }
                if(this.itemHead.CountBoxLosses <= 0 ){
                    this.$fun.alert('Ingrese numero de cajas','warning');
                    this.$refs.txtCountBoxLosses.focus();
                    return
                }
                if(this.itemHead.CountLossesKg <= 0 ){
                    this.$fun.alert('Ingrese Kg de mermas','warning');
                    this.$refs.txtCountLossesKg.focus();
                    return
                }
                if(this.itemHead.Observaciones == undefined ){
                    this.$fun.alert('Ingrese observaciones','warning');
                    this.$refs.txtObservaciones.focus();
                    return
                }
                console.log('item',val);
                this.$fun.alert("Esta de seguro de guardar ?", "question")
                .then(res => {
                    if (res.value) {
                        _sFrzChamberPalletLosseService
                        .save(val, this.$fun.getUserID())
                        .then(r => {
                            if (r.status == 200) {
                                this.$fun.alert("Enviado correctamente", "success"); 

                            }
                        });
                    }
                });

            },
           
          

        },
        created(){
            // this.listPallet();
            
        },
        
            
       
    }
</script>